<template>
  <div class="step">
    <h1>{{appText.notfound_title}}</h1>
    <h2 v-html="appText.notfound_text"></h2>

    <validation-observer v-slot="{ invalid, handleSubmit }" tag="div" class="form_outer">
      <form @submit.prevent="handleSubmit(onSubmit)" class="form_wrap">
        <div class="split_field">
          <validation-provider name="Your Name" rules="required" v-slot="{ errors }" tag="div" class="field">
            <label for="name" :class="{ shrunk: nameLabel }">{{appTextStatic.notFound.nameLabelText[language]}}</label>
            <input v-model="name" type="text" id="name" @focus="nameLabel = true" @blur="!$event.target.value ? nameLabel = false : null" :class="{error_input: errors[0]}">
            <div role="error" class="field_error" v-if="errors[0]">{{appTextStatic.notFound.nameErrorText[language]}}</div>
          </validation-provider>

          <validation-provider name="Email" rules="required|email" v-slot="{ errors }" tag="div" class="field">
            <label for="email" :class="{ shrunk: emailLabel }">{{appTextStatic.notFound.emailLabelText[language]}}</label>
            <input v-model="email" type="email" id="email" @focus="emailLabel = true" @blur="!$event.target.value ? emailLabel = false : null" :class="{error_input: errors[0]}">
            <div role="error" class="field_error" v-if="errors[0]">{{appTextStatic.notFound.emailErrorText[language]}}</div>
          </validation-provider>
        </div>

        <validation-provider name="Your Sector" rules="required"  tag="div" class="field select_diff" :class="{ shrunk: sectorLabel, unshrunk: !sectorLabel }">
          <label for="sector" :class="{ shrunk: sectorLabel }">{{appTextStatic.notFound.sectorLabelText[language]}}</label>
          <v-select 
            :options="fullSectors" 
            :searchable="false" 
            v-model="sector"
            name="sector"
            :clearable="false" 
            label="Sector" 
            placeholder=""
            inputId="sector"
            :class="{ error_input: selectActivated && sector == null }"
            @search:focus="sectorLabel = true"
            @search:blur="(sector == null ? sectorLabel = false : sectorLabel = true), selectActivatedToggle()">
            <template #selected-option="{ Sector }">
              <span v-html="Sector"></span>
            </template>
            <template v-slot:option="option">
              <span v-html="option.Sector"></span>

            </template>
          </v-select>
          <div role="error" class="field_error" v-if="selectActivated && sector == null">{{appTextStatic.notFound.sectorErrorText[language]}}</div>
        </validation-provider>

        <validation-provider name="Your Message" rules="required" v-slot="{ errors }" tag="div" class="field">
          <label for="message" class="top_shrink" :class="{ shrunk: MessageLabel }">{{appText.notfound_message}}</label>
          <textarea v-model="message" id="message" rows="6" @focus="MessageLabel = true" @blur="!$event.target.value ? MessageLabel = false : null" :class="{error_input: errors[0]}"></textarea>
          <div role="error" class="field_error" v-if="errors[0]">{{appTextStatic.notFound.messageErrorText[language]}}</div>
        </validation-provider>

        <div class="success" v-if="success" v-html="appText.notfound_submission"></div>

        <div class="return" v-if="success"><a :href="siteUrl">{{appTextStatic.notFound.returnButtonLabel[language]}}</a></div>

        <div role="error" class="error" v-if="error">{{appTextStatic.notFound.submitErrorMessage[language]}}</div>

        <div class="btn_wrap">
          <button type="submit" :disabled="invalid || success" class="btn btn-white">{{appTextStatic.notFound.notFoundButtonLabel[language]}}</button>
        </div>
      </form>
    </validation-observer>
    <back-button :goBackXSteps="2" />
  </div>
</template>

<script>

import { AppText } from '@/store/AppText';
import axios from 'axios'
import {mapState} from "vuex";

  export default {
    name: "NotFound",
    data() {
      return {
        name: null,
        email: null,
        message: null,
        nameLabel: false,
        emailLabel: false,
        sectorLabel: false,
        MessageLabel: false,
        success: false,
        error: false,
        sector: null,
        selectActivated: false,
        siteUrl: process.env.VUE_APP_TUC_URL + '/about-unions',
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;

      this.$store.dispatch('getCSRFToken').catch((e) => {
        console.log('Error Catch:');
        console.log(e);
      });
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    computed: {
      ...mapState({
        sectors: 'sectors'
      }),
      fullSectors: function () {
        const list = []
        this.sectors.forEach((sector) => {
          list.push(sector.name);
        })
        list.push("I'm not sure");
        return list
      },
      computedForm() {
        return this.$refs.form;
      }
    },
    methods: {
      selectActivatedToggle() {
        this.selectActivated = true;
      },
      onSubmit() {
        this.analytics('cant_find_job', 'attempt')
        this.error = false;
        
        this.$store.dispatch('sendNotFound', {
          name: this.name,
          email: this.email,
          sector: this.sector,
          message: this.message
        }).then((response) => {
            // console.log(response);
            this.analytics('cant_find_job', 'submitted')
            this.success = true;
          })
          .catch((error) => {
            this.analytics('cant_find_job', 'error')
            console.log(error);
            this.error = true;
          })
          
      }
    }
  }
</script>
<style lang="scss">
  .select_diff{
    &.unshrunk{
      .v-select {
        .vs__dropdown-toggle{
          padding: 26px 10px 26px 10px !important;
        }
        
      }
    }
    label{
      z-index: 2 !important;
    }
    .v-select {
      height: 61px;
      max-height: 61px;
      padding: 0px !important;

      &.vs--open{
        outline: 0 !important;
        border: 2px solid #C92B51 !important;
      }

      .vs__actions{
        display: none !important;
      }


      .vs__search {
        color: black !important;
        border: unset !important;
        // display: none;
        height: 0px;
        width: 0px;
      }

      .vs__clear {
          
      }

      .vs__dropdown-toggle {
        padding: 26px 10px 7px 10px !important;
        border: unset !important;
      }

      .vs__selected-options {

      }

      .vs__open-indicator {

      }

      .vs__dropdown-menu {
        width: calc(100%) !important;
        top: calc(100% - 2px);
        left: 0px !important;
        .vs__dropdown-option  {

        }
        .vs__dropdown-option--highlight {

        }
      }

      .vs__selected {
        color: black!important;
        margin-top: -2px;
      }
    }
  }
</style>
<style scoped lang="scss">
  .return{
      margin-top: 16px;
      margin-bottom: 5px;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    max-width: 650px;
    margin: 0 auto 40px;
  }

  h2 {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    max-width: 650px;
    margin: 0 auto 40px;
  }

  .step {
    width: 950px;
    max-width: 100%;
    background-color: #652E55;
    border-radius: 9px;
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      max-width: 100%;
      padding: 35px;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }
  .error {
    text-align: left;
  }
  .form_wrap{
    .field{
      label{
        @media (max-width: 767px) {
          width: calc(100% - 20px);
        }
      }
    }
  }
</style>
