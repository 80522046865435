<template>
  <div class="step">
    <h1>{{appText.step_two_title}}</h1>

    <div class="btn-container extra_margin">
      <button class="btn btn-white" @click="next('yes')" :aria-pressed="`${(union && union === 'yes' ? true : false)}`">
        {{appTextStatic.colleaguesStep.buttonOneLabel[language]}}
      </button>
      <button class="btn btn-white" @click="next('no')">
        {{appTextStatic.colleaguesStep.buttonTwoLabel[language]}}
      </button>
      <button class="btn btn-white" @click="next('unsure')" :aria-pressed="`${(union && union === 'unsure' ? true : false)}`">
        {{appTextStatic.colleaguesStep.buttonThreeLabel[language]}}
      </button>
    </div>

    <transition-slide-toggle :speed="300">
      <div id="message" v-show="union && union === 'yes'">
        <div class="inner_message">
          <span v-html="appText.step_two_button_one_text"></span>
          <span>{{appTextStatic.colleaguesStep.textOne[language]}}, <a @click="analytics('click', 'unions list link')" :href="appTextStatic.colleaguesStep.linkURL[language]" target="_blank">{{appTextStatic.colleaguesStep.linkOne[language]}}</a></span>
        </div>
      </div>
    </transition-slide-toggle>
    <transition-slide-toggle :speed="300">
      <div id="message2" v-show="union && union === 'unsure'">
        <div class="inner_message">
          <span v-html="appText.step_two_button_three_text"></span>
          <span>{{appTextStatic.colleaguesStep.textThree[language]}}, <a @click="analytics('click', 'unions list link')" :href="appTextStatic.colleaguesStep.linkURL[language]" target="_blank">{{appTextStatic.colleaguesStep.linkThree[language]}}</a></span>
        </div>
      </div>
    </transition-slide-toggle>
    <back-button />
  </div>
</template>

<script>
  import { AppText } from '@/store/AppText';
  import TransitionSlideToggle from "./TransitionSlideToggle";
  import { mapMutations } from "vuex";

  export default {
    name: "ColleaguesStep",
    components: {TransitionSlideToggle},
    data() {
      return {
        union: null,
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
     watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    methods: {
      next(choice) {
        this.union = choice;
        this.analytics('colleague_btn', choice)

        if (choice === 'no') {
          this.setActiveStep(4);
        }
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      }),
    }
  }
</script>

<style scoped lang="scss">
  .step {
    width: 100%;
    max-width: 560px;
    background-color: #652E55;
    border-radius: 9px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 19px;
    line-height: 24px;
  }

  .btn {
    margin-top: 30px;
    font-family: 'Avenir', monospace, sans-serif;
  }

  #message,
  #message2 {
    background-color: #ffffff;
    border-radius: 5px;
    color: #652e55;
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;

    .inner_message {
      padding:20px;
    }
  }
</style>
