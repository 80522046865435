export class AppTextStatic{
    whyStep = {
        placeholder: {
            "en": "Please select",
            "cy": "Dewiswch"
        },
        nextLabel:{
            "en": "Next",
            "cy": "Nesaf",
        },
        continueLabel: {
            "en": "Continue to find a union",
            "cy": "Bwrw ymlaen i ddod o hyd i undeb"
        },
        orLabel: {
            "en": "or",
            "cy": "neu"
        },
        clickHereLabel: {
            "en": "click here to get in touch with ACAS",
            "cy": "cliciwch yma i gysylltu ag ACAS"
        },
        openLinkLabel: {
            "en": "Link opens in a new tab",
            "cy": "Dolen yn agor mewn tab newydd"
        },
        options: {
            "en": [
                    {
                        label: `I'm interested in joining a union`,
                        id: 1
                    },
                    {
                        label: `I need immediate help at work`,
                        id: 2
                    }
                ],
            "cy": [
                    {
                        label: `Mae gen i ddiddordeb mewn ymuno ag undeb`,
                        id: 1
                    },
                    {
                        label: `Mae angen help arnaf ar unwaith yn y gwaith`,
                        id: 2
                    }
                ],
        }
    };

    colleaguesStep = {
        buttonOneLabel: {
            "en": "Yes",
            "cy": "Oes"
        },
        buttonTwoLabel: {
            "en": "No",
            "cy": "Nac oes"
        },
        buttonThreeLabel: {
            "en": "I'm not sure",
            "cy": "Dydw i ddim yn siŵr"
        },
        textOne: {
            "en": "If you know the name of the union",
            "cy": "Os ydych chi’n gwybod beth yw enw’r undeb"
        },
        linkOne: {
            "en": " click here to find it on our list now. ",
            "cy": " cliciwch yma i ddod o hyd iddo ar ein rhestr nawr. "
        },
        textThree: {
            "en": "If you know the name of the union",
            "cy": "Os ydych chi’n gwybod beth yw enw’r undeb"
        },
        linkThree: {
            "en": " click here to find it on our list now. ",
            "cy": " cliciwch yma i ddod o hyd iddo ar ein rhestr nawr. "
        },
        linkURL: {
            "en": "https://www.tuc.org.uk/unions",
            "cy": "https://www.tuc.org.uk/cy/holl-undebau"
        }
    };

    industryStep = {
        errorMessage: {
            "en": "Please select the industry you work in.",
            "cy": "Dewiswch y diwydiant lle'r ydych yn gweithio."
        },
        placeholder: {
            "en": "Please select your industry",
            "cy": "Dewiswch eich diwydiant"
        },
        findErrorMessage: {
            "en": "There was an issue with finding your industry, please try again.",
            "cy": "Roedd problem wrth ddod o hyd i’ch diwydiant. Rhowch gynnig arall arni."
        },
        nextButtonLabel: {
            "en": "Next",
            "cy": "Nesaf"
        }
    };

    signUpInterupt = {
        skipButtonLabel: {
            "en": "Skip",
            "cy": "Anwybyddu"
        }
    };

    signUpForm = {
        emailPlaceholder: {
            "en": "Enter your email address",
            "cy": "Rhowch eich cyfeiriad e-bost"
        },
        submitButtonLabel: {
            "en": "Sign up",
            "cy": "Cofrestru"
        },
        emailErrorText: {
            "en": "Email is not valid.",
            "cy": "Dydy'r e-bost ddim yn ddilys."
        }
    };

    pageHeader = {
        visitTUC: {
            "en": "Visit tuc.org.uk (Link opens in a new tab)",
            "cy": "Ewch i tuc.org.uk(Dolen yn agor mewn tab newydd)"
        }
    };

    resultsStep = {
        thankYouMessage: {
            "en": "Thank you for signing up!",
            "cy": "Diolch am ymuno!"
        },
        returnLink: {
            "en": "Return to the TUC Website",
            "cy": "Yn ôl i Wefan TUC"
        },
        employersLabel: {
            "en": "Employers Represented Include",
            "cy": "Rhai o’r cyflogwyr a gynrychiolir"
        },
        membershipLabel: {
            "en": "Membership Benefits",
            "cy": "Manteision Aelodaeth"
        },
        costLabel: {
            "en": "Cost of membership",
            "cy": "Cost aelodaeth"
        },
        webLabel: {
            "en": "Web",
            "cy": "Gwe"
        },
        websiteLabel: {
            "en": "website",
            "cy": "gwefan"
        },
        newTabLabel: {
            "en": "Link opens in a new tab",
            "cy": "Dolen yn agor mewn tab newydd"
        },
        emailLabel: {
            "en": "Email",
            "cy": "E-bost"
        },
        telephoneLabel: {
            "en": "Tel",
            "cy": "Ffôn"
        },
        readMoreLabel: {
            "en": "Read more",
            "cy": "Gweld mwy"
        },
        readLessLabel: {
            "en": "Read less",
            "cy": "Gweld llai"
        },
        joinLabel: {
            "en": "Join",
            "cy": "Ymuno â"
        },
        backToTopLabel: {
            "en": "Back to top",
            "cy": "Yn ôl i’r brig"
        },
        descLabel: {
            "en": "desc",
            "cy": "disgrifiad"
        }
    };

    notFound = {
        nameLabelText: {
            "en": "Your name",
            "cy": "Eich enw"
        },
        nameErrorText: {
            "en": "Please enter your name",
            "cy": "Rhowch eich enw"
        },
        emailLabelText: {
            "en": "Your email",
            "cy": "Eich e-bost"
        },
        emailErrorText: {
            "en": "Please enter your email",
            "cy": "Rhowch eich cyfeiriad e-bost"
        },
        sectorLabelText: {
            "en": "Your sector",
            "cy": "Eich sector"
        },
        sectorErrorText: {
            "en": "Please select your sector",
            "cy": "Dewiswch eich sector"
        },
        messageErrorText: {
            "en": "Please enter your message",
            "cy": "Rhowch eich neges"
        },
        returnButtonLabel: {
            "en": "Return to the TUC Website",
            "cy": "Yn ôl i Wefan TUC"
        },
        submitErrorMessage: {
            "en": "There was an issue when sending, please check the form above and try to resend.",
            "cy": "Roedd problem wrth anfon. Edrychwch ar y ffurflen uchod a rhoi cynnig arall arni."
        },
        notFoundButtonLabel: {
            "en": "Send message",
            "cy": "Anfon neges"
        },
    };

    cookiePopup = {
        cookieTitle: {
            "en": "Cookie Preferences",
            "cy": "Dewisiadau Cwcis"
        },
        cookieBody: {
            "en": "We use necessary cookies to make the TUC website work. We also set analytics cookies that tell us how the website is used. You can accept all of these cookies or manage your consent under settings.",
            "cy": "Rydyn ni’n defnyddio cwcis angenrheidiol i wneud i wefan TUC weithio. Rydyn ni hefyd yn gosod cwcis dadansoddi sy’n dweud wrthym sut mae’r wefan yn cael ei defnyddio. Gallwch dderbyn pob cwci neu reoli eich caniatâd o dan yr adran gosodiadau."
        },
        cookieAcceptButtonLabel: {
            "en": "Accept",
            "cy": "Derbyn"
        },
        cookieDeclineButtonLabel: {
            "en": "No, take me to settings",
            "cy": "Na, ewch â fi i'r adran gosodiadau"
        },       
    };

    footerStep = {
        privacyLink: {
            "en": "Privacy Policy",
            "cy": "Polisi Preifatrwydd"
        },
        footerText: {
            "en": "© Trades Union Congress " + new Date().getFullYear() + ", unless otherwise stated.",
            "cy": "© Cyngres yr Undebau Llafur " + new Date().getFullYear() + ", oni nodir yn wahanol."
        }
    };
}