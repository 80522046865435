<template>
  <div class="step">
    <h1>{{appText.sign_up_title}}</h1>

    <p>{{appText.sign_up_intro_p1}}</p>

    <p class="bigger">{{appText.sign_up_intro_p2}}</p>

    <SignupForm :gotoAfter="6" />

    <button type="submit" @click="setActiveStep(6), analytics('signup', 'skipped')" class="btn btn-text">{{appTextStatic.signUpInterupt.skipButtonLabel[language]}}</button>

    <back-button />
  </div>
</template>

<script>

  import { AppText } from '@/store/AppText';
  import { mapState } from 'vuex';
  import { mapMutations } from "vuex";

  import SignupForm from "./SignupForm";

  export default {
    name: "SignupInterupt",
    components: {SignupForm},
    data() {
      return {
        toggles: [],
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
    computed: {
      ...mapState({
        unions: 'unions'
      })
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      },
      unions: function(newVal) {
        this.toggles = []
        newVal.forEach(() => {
          this.toggles.push(false)
        })
      }
    },
    methods: {
      fixImgUrl(html) {
        return html.replace('src="/sites', 'src="https://www.tuc.org.uk/sites')
      },
      extractUrl(html) {
        const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
        return html.match(urlRegex)[0];
      },
      toggleReadmore(i) {
        this.toggles.splice(i, 1, !this.toggles[i])
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      })
    }
  }
</script>
<style scoped lang="scss">
  h1 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    max-width: 600px;
    margin: 0 auto 15px;
  }

  .step {
    width: 100%;
    max-width: 460px;
    background-color: #652E55;
    border-radius: 9px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      padding: 0;
      max-width: 100%;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }


.btn-text {
  font-size: 18px;
  margin-bottom: 20px;
}

p {
  text-align: center;
  margin: 10px 0;

  &:last-of-type {
    margin-bottom: 30px;
  }
}

p.bigger {
  font-size: 18px;
}
</style>
