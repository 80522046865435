<template>
    <div class="step">
        <h1>{{appText.homepage_title}}</h1>
        <p class="pTop" v-html="appText.homepage_text">
        </p>
        <transition-slide-toggle :speed="300">
            <div class="readmore" v-show="showMore" >
              <p v-html="appText.homepage_more_text"></p>
            </div>
        </transition-slide-toggle>

        <button class="btn btn-text tellmore" :aria-pressed="`${showMore}`" @click="showMore = !showMore, analytics('tell_me_more', showMore ? 'opened' : 'closed')">
            {{appText.homepage_more_button}}
            <svg aria-hidden="true" :class="{ rotate: showMore }" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path></svg>
        </button>

      <p class="tagline" v-html="appText.homepage_subtext">
      </p>

      <button class="btn btn-red" @click="setActiveStep(2), analytics('button', 'find_a_union')">
        {{appText.homepage_button}}
      </button>
    </div>
</template>

<script>
  import { AppText } from '@/store/AppText';
  import { mapMutations } from 'vuex';
  import TransitionSlideToggle from "./TransitionSlideToggle";

  export default {
    name: "WelcomeStep",
    components: {TransitionSlideToggle},
    data() {
      return {
        showMore: false,
        language: null,
        appText: AppText
      }
    },
    created(){
      this.language = this.$store.getters.getLanguage;
      this.appText = this.$store.getters.getAppText;
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    methods: {
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      })
    }
  }
</script>

<style scoped lang="scss">
  .step {
    width: 100%;
    max-width: 460px;
    background-color: #652E55;
    border-radius: 9px;
    padding: 30px 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      max-width: 100%;
      padding: 25px;
      padding-top: 30px !important;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
    padding: 0 50px;
    margin: 0;
    @media (max-width: 767px) {
      padding: 0 !important;
    }
  }

  p {
    font-size: 19px;
    line-height: 24px;
  }
  p.pTop{
      text-align: center;
  }
  .tellmore {
    margin-bottom: 10px;
    svg {
      width: 14px;
      height: 16px;
      margin-left: 7px;
      transition: .15s;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .tagline {
    font-weight: bold;
    padding: 0 80px;

    margin: 30px 0;
    text-align: center;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .readmore {
    overflow: hidden;
    white-space: break-spaces;

    p {
      text-align: center;
      font-size: 18px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
</style>
