<template>
  <footer class="footer footer_wrap">
    <div class="footer_inner">
      <p>{{appTextStatic.footerStep.footerText[language]}} <a :href="privacyUrl">{{appTextStatic.footerStep.privacyLink[language]}}</a></p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "FooterBar",
    data() {
        return {
            privacyUrl: process.env.VUE_APP_TUC_URL + '/tuc-privacy-notice',
            language: null,
            appTextStatic: null,
        }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.language = this.$store.getters.getLanguage;
    },
    watch: {
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    }
  }
</script>

<style scoped lang="scss">
  .footer_wrap{
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #000000;
    transition: .25s;
    z-index: 100;

    @media (max-width: 767px) {

    }

    .footer_inner{
      max-width: 620px;
      height: 50px;
      margin: 0 auto;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      @media (max-width: 767px) {

      }

      p{
        font-size: 14px;
        line-height: 16px;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
      }
      a{
        text-decoration: underline;
        color: #fff;
        margin-left: 6px;
      }
    }
  }
</style>
