<template>
    <div id="sliding-popup" class="sliding-popup-bottom" style="height: auto; width: 100%; bottom: 0px;">
        <div class="eu-cookie-compliance-banner eu-cookie-compliance-banner-info eu-cookie-compliance-banner--opt-in">
            <div class="popup-content info eu-cookie-compliance-content">
                <div id="popup-text" class="eu-cookie-compliance-message">
                    <h2>{{appTextStatic.cookiePopup.cookieTitle[language]}}</h2>
                    <p>{{appTextStatic.cookiePopup.cookieBody[language]}}</p>
                </div>
                <div id="popup-buttons" class="eu-cookie-compliance-buttons">
                    <button type="button" class="agree-button eu-cookie-compliance-secondary-button" @click="acceptCookies">{{appTextStatic.cookiePopup.cookieAcceptButtonLabel[language]}}</button>
                    <a :href="siteUrl" class="decline-button eu-cookie-compliance-default-button">{{appTextStatic.cookiePopup.cookieDeclineButtonLabel[language]}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from "vuex";

  export default {
    name: "CookiePopup",
    components: {},
    data() {
        return {
            siteUrl: process.env.VUE_APP_TUC_URL + '/cookies-policy',
            language: null,
            appTextStatic: null,
        }
    },
    created(){
        this.appTextStatic = this.$store.getters.getAppTextStatic;
        this.language = this.$store.getters.getLanguage;
    },
    watch: {
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    methods: {
      acceptCookies() {
        this.$cookies.set('cookie-agreed', 2)
        this.$cookies.set('cookie-on-analy', 1)
        this.setShowCookies(0);
        this.$emit('updateHead');
      },
      ...mapMutations({
        setShowCookies: 'setShowCookies'
      })
    }
  }
</script>

<style scoped lang="scss">
    .sliding-popup-bottom{
        margin: 0;
        padding: 0;
        width: 100%;
        z-index: 99999;
        left: 0;
        text-align: center;
        position: fixed;
        background: #36b1b6;

        div {
            color: #ffffff;
        }

        .eu-cookie-compliance-content{
            margin: 0 auto;
            display: inline-block;
            text-align: left;
            width: 100%;
            max-width: 75%;
            margin-top: 20px;

            
            .eu-cookie-compliance-message{
                float: left;
                margin: 5px 0 0;
                max-width: 73%;
                padding-right: 20px;
                @media (max-width: 960px){
                    max-width: 100%;
                }
            }

            h2{
                line-height: 1.4;
                margin: 0 0 5px;
                font-weight: 700;
                font-size: 1.4em;
                margin-bottom: 15px;
            }

            p{
                color: #fff;
                font-size: 1em;
                display: inline;
                font-weight: 700;
                line-height: 1.4;
                margin: 0 0 5px;
                font-family: 'Avenir', sans-serif;
            }
            .eu-cookie-compliance-buttons {
                float: right;
                margin: 0 0 1em;
                max-width: 40%;

                @media (max-width: 960px){
                    float: none;
                }

                @media (max-width: 600px){
                    clear: both;
                    max-width: 100%;
                    margin: 5px 0 1em;
                }

                .agree-button{
                    font-family: Arial,sans-serif;
                    margin: 0;
                    overflow: visible;
                    text-transform: none;
                    cursor: pointer;
                    border: none;
                    vertical-align: middle;
                    background: #8b336e;
                    color: #fff;
                    text-shadow: none;
                    box-shadow: none;
                    text-decoration: none;
                    padding: 8px 40px;
                    margin-right: 10px;
                    display: block;
                    margin-top: 53px;
                    font-size: 1.2em;

                    @media (max-width: 960px){
                        margin-top: 20px;
                        float: left;
                        width: 100%;
                    }
                }
                .decline-button{
                    letter-spacing: normal;
                    word-spacing: normal;
                    display: inline-block;
                    text-align: center;
                    align-items: flex-start;
                    text-indent: 0px;
                    border: 0;
                    background: 0 0;
                    color: #fff;
                    text-shadow: none;
                    box-shadow: none;
                    text-decoration: none;
                    cursor: pointer;
                    margin-right: 5px;
                    margin-top: 1em;
                    vertical-align: middle;
                    overflow: visible;
                    width: auto;
                    font-family: Arial,sans-serif;
                    font-weight: 700;
                    padding: 4px 8px;
                    border-radius: 6px;
                    font-size: 16px;
                    padding-left: 0px;

                    &:hover{
                        text-decoration: underline;;
                    }
                }
                

            }
        }

    }
</style>
