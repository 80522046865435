<template>
<div class="signup_wrap">
  <validation-observer v-slot="{ invalid, handleSubmit }" tag="div" class="form_outer">
    <form @submit.prevent="handleSubmit(onSubmit)" class="form_wrap">
      <validation-provider name="Email" rules="required|email" v-slot="{ errors }" tag="div" class="field">
        <label for="email" :class="{ shrunk: emailLabel }">{{appTextStatic.signUpForm.emailPlaceholder[language]}}</label>
        <input v-model="email" type="email" id="email" @focus="emailLabel = true" @blur="!$event.target.value ? emailLabel = false : null">
        <div class="field_error" v-if="errors[0]">{{ appTextStatic.signUpForm.emailErrorText[language] }}</div>
      </validation-provider>

      <p class="small">
        {{appText.sign_up_consent}}
      </p>

      <div class="error" v-if="error">{{appText.sign_up_error}}</div>

      <div class="btn_wrap">
        <button type="submit" :disabled="invalid" class="btn btn-white">{{appTextStatic.signUpForm.submitButtonLabel[language]}}</button>
      </div>
    </form>
  </validation-observer>
</div>
</template>

<script>
  import { AppText } from '@/store/AppText';
  import { mapMutations } from "vuex";

  export default {
    name: "SignupForm",
    props: ['gotoAfter'],
    data() {
      return {
        email: null,
        emailLabel: false,
        error: false,
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    methods: {
      onSubmit() {
        this.error = false;
        
        this.$store.dispatch('signUp', {
          email: this.email ? this.email : null,
          sector: this.$store.state.selectedSector
        })
        .then((response) => {
          if(response.data.result == "success")
          {
            this.submitSuccess(response);
          }
          else
          {
            this.submitError(response);  
          }
        })
        .catch((error) => {
          this.submitError(error);
          // this.submitSuccess(error); // FOR TESTING PURPOSES
        })
      },
      submitSuccess(response){ 
        this.analytics('signup', 'success')

        this.$store.state.signUpCompleted = true;
        if (this.gotoAfter) this.setActiveStep(this.gotoAfter)
        this.error = false;          
      },
      submitError(error){
          this.analytics('signup', 'error')
          
          this.error = true;
          this.$store.state.signUpCompleted = false;
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      })
    }
  }
</script>

<style scoped lang="scss">
.form_outer{
  margin: 0 auto;
  width: 420px;
  max-width: 100%;
}

p.small {
  font-size: 14px;
}

</style>
