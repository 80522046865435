export class AppText{
    homepage_title = "Welcome to your union finder!";
    homepage_text  = "More than six million workers in the UK are in a union – <strong>from nurses to pilots</strong>, actors to lorry drivers. Find the right union for you, today.";
    homepage_more_text = "Joining a union is affordable and easy, and the benefits are huge.\n\nUnions help workers come together, demand a better deal from their employers, and raise standards for all of us.\n\nUnions are the reason we all enjoy paid holiday, maternity leave, and lunch breaks. They’ll offer you free legal advice if you need it, training and learning opportunities, and even discounts and offers.";
    homepage_more_button = "Tell me more";
    homepage_subtext =  "Together, we’re stronger. So join your union today";
    homepage_button =  "Find a union aaaaa";
    step_one_title =  "Why are you here today?";
    step_one_label =  "I am here today because";
    step_one_immediate =  "Okay – if you’re in urgent need of support at work, some unions can’t help unless you’re already a member, though they may offer some helpful pointers. ACAS offer free advice, though they can’t represent you in the workplace like a union.";
    step_two_title =  "Great, now – do you have colleagues who are already union members, or is there a union in your workplace?";
    step_two_button_one =  "Yes";
    step_two_button_two =  "No";
    step_two_button_three =  "I'm not sure";
    step_two_button_one_text =  "If your workplace already recognises a union, or your colleagues are already members of one, you should join the same. You’re much stronger together. If you know the name of the union, <a href=\"https://www.tuc.org.uk/unions\" target=\"_blank\"> click here to find it on our list now. </a>";
    step_two_button_three_text =  "Alt: If your workplace already recognises a union, or your colleagues are already members of one, you should join the same. You’re much stronger together. If you know the name of the union, <a href=\"https://www.tuc.org.uk/unions\" target=\"_blank\"> click here to find it on our list now. </a>";
    step_three_title =  "Find your industry";
    step_three_text =  "Okay and finally, what kind of job do you have? Search for your industry from the drop down menu below.";
    step_three_label =  "I work in";
    step_three_not_found =  "I can't find my industry";
    notfound_title =  "Hmmm, we can’t seem to find your industry. Sorry about that!";
    notfound_text =  "Drop us an email telling us more about your job and industry, and our team will get back to you to let you know which union is best for you.";
    notfound_message =  "Your message - include your job and industry information here";
    notfound_button =  "Send message";
    notfound_submission =  "Thank you for telling us more about your job and industry. We will get back to you as soon as possible!";
    results_title =  "Okay! Based on the information you gave us, we think you’d fit in well with the following unions.";
    results_signoff =  "Thanks for using the TUC union finder!"
}