<template>
  <div class="step">
    <h1>{{appText.step_one_title}}</h1>

    <div class="select-input" :class="{ darken: darkenLabel }">
      <label for="why">{{appText.step_one_label}}</label>
      <v-select
        v-model="why"
        :options="appTextStatic.whyStep.options[language]"
        :searchable="false"
        @input="analytics('why', $event.label)"
        :clearable="false"
        :placeholder="appTextStatic.whyStep.placeholder[language]"
        inputId="why"
        @open="darkenLabel = true" @close="(isOpen) => darkenLabel = isOpen"
      ></v-select>
    </div>

    <transition-slide-toggle :speed="300">
      <div class="message" v-show="why !== null ? why.id === 2 : false">
        <div class="inner_message">
          <span v-html="appText.step_one_immediate"></span>
          <span class="underline" tabindex="0" @click="goNext()">{{appTextStatic.whyStep.continueLabel[language]}}</span>, {{appTextStatic.whyStep.orLabel[language]}}
          <a @click="analytics('click', 'acas link');" href="https://www.acas.org.uk/contact" target="_blank">{{appTextStatic.whyStep.clickHereLabel[language]}}.<span class="sr-only">({{appTextStatic.whyStep.openLinkLabel[language]}})</span></a>
        </div>
      </div>
    </transition-slide-toggle>

    <button class="btn btn-white" @click="goNext" v-if="why !== null ? why.id === 1 : false">
      {{appTextStatic.whyStep.nextLabel[language]}}
    </button>
    <back-button /> 
  </div>
</template>

<script>

  import { AppText } from '@/store/AppText';
  import TransitionSlideToggle from "./TransitionSlideToggle";
  import { mapMutations } from "vuex";

  export default {
    name: "WhyStep",
    components: {TransitionSlideToggle},
    data() {
      return {
        why: null,
        darkenLabel: false,
        appText: AppText,
        appTextStatic: null,
        language: null
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    },
    methods: {
      goNext() {
        this.analytics('why_next', this.why.label)
        this.setActiveStep(3);
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      })
    }
  }
</script>

<style scoped lang="scss">
  .step {
    width: 100%;
    max-width: 460px;
    background-color: #652E55;
    border-radius: 9px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      max-width: 100%;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 0;

    @media (max-width: 767px){
      font-size: 24px;
    }
  }

  p {
    font-size: 19px;
    line-height: 24px;
  }

  .btn {
    margin-top: 30px;
  }

  .message {
    background-color: #ffffff;
    border-radius: 5px;
    color: #652e55;
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;

    .inner_message {
      padding: 20px;

      span{
        display: block;
        &.underline {
          display: inline-block;
          cursor: pointer;
          text-decoration: underline;
          color: #843864;
          transition: .15s;
        }

        span.underline:hover {
          color: #a8477f;
        }
      }


    }
  }
</style>
