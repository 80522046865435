<template>
  <div class="progressbar_wrap" :class="{ show: $store.state.activeStep != 1 }" aria-label="progress">
    <div class="progressbar_inner">

      <div class="progressbar_item" v-for="stage in $store.state.stages" :key="stage.name">
        <div class="progressbar_icon" :class="{ active: stage.active, done: stage.done }" :aria-current="stage.active && !stage.done"></div>
        <div class="progressbar_text">
          <span v-if="language == 'cy'">{{ stage.name_cy }}</span>
          <span v-else>{{ stage.name }}</span>
          <span v-if="stage.done && language == 'en'" class="sr-only">completed</span>
          <span v-if="stage.done && language == 'cy'" class="sr-only">wedi ei gwblhau</span>
          <span v-if="!stage.done && language == 'en'" class="sr-only">not completed</span>
          <span v-if="!stage.done && language == 'cy'" class="sr-only">heb ei gwblhau</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "ProgressBar",
    data() {
        return {
          language: null
        }
    },
    created(){
      this.language = this.$store.getters.getLanguage;
    },
    watch: {
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      }
    }
  }
</script>

<style scoped lang="scss">
.progressbar_wrap {
  height: 66px;
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  background: #535052;
  transform: translateY(100%);
  transition: .25s;
  z-index: 100;

  &.show {
    transform: translateY(0);
  }

  @media (max-width: 767px) {
    height: 75px;
  }

	.progressbar_inner {
    max-width: 620px;
    height: 66px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    @media (max-width: 767px) {
      height: 75px;
    }

		.progressbar_item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }

			.progressbar_icon {
        background: white;
        border-radius: 50%;
        height: 27px;
        width: 27px;
        margin-right: 10px;
        margin-bottom: 0;
        transition: .2s;

        &.active {
          border: 2px solid #C92B51;
          background: #E5C4CC;
        }

        &.done {
          border: 2px solid #C92B51;
          background: #C92B51;
          position: relative;

          &:before {
            position: absolute;
            top: 7px;
            left: 4px;
            content: "";
            width: 14px;
            height: 6px;
            transform: rotate(-45deg);
            border-bottom: 2px solid white;
            border-left: 2px solid white;
          }
        }

        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 5px;
        }
			}

			.progressbar_text {

			}
		}
	}
}
</style>
