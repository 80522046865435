


































import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import PageHeader from "./components/PageHeader.vue";
import WelcomeStep from "./components/WelcomeStep.vue";
import WhyStep from './components/WhyStep.vue';
import ColleaguesStep from "./components/ColleaguesStep.vue";
import IndustryStep from "./components/IndustryStep.vue";
import ResultsStep from "./components/ResultsStep.vue";
import ProgressBar from "./components/ProgressBar.vue";
import NotFound from "./components/NotFound.vue";
import { AppText } from './store/AppText';
import FooterBar from './components/FooterBar.vue';
import CookiePopup from './components/CookiePopup.vue';
import SignupInterupt from './components/SignupInterupt.vue';
import TransitionSlideToggle from "./components/TransitionSlideToggle.vue";

export default Vue.extend({
  name: 'App',
  components: {
    PageHeader,
    WelcomeStep,
    WhyStep,
    ColleaguesStep,
    IndustryStep,
    ProgressBar,
    ResultsStep,
    NotFound,
    FooterBar,
    CookiePopup,
    SignupInterupt,
    TransitionSlideToggle
  },
  data() {
    return {
      sectors: [],
      appText: AppText,
      backgroundImage: '',
      loaded: 0
    }
  },
  computed: {
    ...mapState({
      activeStep: 'activeStep',
      notFound: 'notFound',
      showCookies: 'showCookies',
    })
  },
  methods: {
    ...mapActions({
      getBackgroundImages: 'getBackgroundImages',
      getAppText: 'getAppText',
      getSectors: 'getSectors'
    }),
    ...mapMutations({
      setShowCookies: 'setShowCookies',
    })
  },
  watch: {
    '$store.state.showCookies': function () {
      if(this.$store.state.showCookies == 0) {
        this.$emit('updateHead');
      }
    }
  },
  beforeMount() {
    const arr = [this.getBackgroundImages()];
    Promise.allSettled(arr).then(() => {
        this.loaded = 1;
        const backgroundImage = new Image();
        backgroundImage.src = this.$store.getters.getBackgroundImage;

        backgroundImage.onload = (event) => {
          this.backgroundImage = backgroundImage.src;
        };
        
    })

  },
  mounted() {
    this.getSectors();
  },
  head: {
    script: function() {
      // Check user cookies
      const cookieAgreed = this.$cookies.get('cookie-agreed');
      const cookieAnalytics = this.$cookies.get('cookie-on-analy');

      const a = [];

      if(cookieAgreed != null && cookieAgreed != 0) {
        this.setShowCookies(0);
        if(cookieAnalytics != null && cookieAnalytics == 1) {
          a.push({type: 'text/javascript', src: 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GA_KEY, async: true, body: false});
          a.push({type: 'text/javascript', async: true, body: false, inner: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.VUE_APP_GA_KEY}');
          `});
          a.push({type: 'text/javascript', async: true, body: false, inner: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.VUE_APP_GTM_KEY}');
          `});
           a.push({type: 'text/javascript', async: true, body: false, inner: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              
              fbq('init', '${process.env.VUE_APP_FB_KEY}');
              fbq('track', 'PageView');
          `});
        }
      } else {
        this.setShowCookies(1);
      }

      return a;
    }
      
  }
});
