<template>
  <div class="step results">
    <div class="subscribed" v-if="this.$store.state.signUpCompleted">{{appTextStatic.resultsStep.thankYouMessage[language]}}</div>
    <h1>{{appText.results_title}}</h1>
    <div v-for="(union, i) in unions" :key="union.nid" class="union_wrap">
      <div class="left" v-html="fixImgUrl(union.field_logo)"></div>
      <div class="right">
        <h2>{{ union.field_full_name.length ? union.field_full_name : union.title_1 }}</h2>
        <div class="union_desc" v-if="union.field_body_paragraph.length" v-html="union.field_body_paragraph">{{appTextStatic.resultsStep.descLabel[language]}}</div>
        <div class="union_btns">
          <a style="margin-top:20px;" v-if="union.field_online_registration_form_1.length" :href="extractPlainURL(union)" @click="analytics('results_join', union.title_1)" target="_blank" role="button" class="btn btn-wine">{{appTextStatic.resultsStep.joinLabel[language]}} {{ union.field_full_name.length ? union.field_full_name : union.title_1 }}<span class="sr-only">(Link opens in a new tab)</span></a>
          <button style="margin-top:20px;" @click="toggleReadmore(i)" class="btn btn-off-wine" :aria-pressed="`${toggles[i]}`"><span v-if="!toggles[i]">{{appTextStatic.resultsStep.readMoreLabel[language]}}</span><span v-else>{{appTextStatic.resultsStep.readLessLabel[language]}}</span></button>
        </div>

        <transition-slide-toggle :speed="300">
          <div class="readmore" v-show="toggles[i]">
            <div class="readmore_inner">
              <div class="represented" v-if="union.field_employers_represented.length">
                <h3>{{appTextStatic.resultsStep.employersLabel[language]}}:</h3>
                <div v-html="union.field_employers_represented"></div>
              </div>
              <div class="benefits" v-if="union.field_membership_benefits.length">
                <h3>{{appTextStatic.resultsStep.membershipLabel[language]}}:</h3>
                <div v-html="union.field_membership_benefits"></div>
              </div>
              <div class="cost" v-if="union.field_union_cost.length">
                <h3>{{appTextStatic.resultsStep.costLabel[language]}}:</h3>
                <div v-html="union.field_union_cost"></div>
              </div>
              <div class="quick web" v-if="union.field_website_union.length">
                <strong>{{appTextStatic.resultsStep.webLabel[language]}}:</strong>
                  <a :href="extractUrl(union.field_website_union)" @click="analytics('results_web', union.title_1)" target="_blank">
                    <span v-if="language == 'en'">{{ union.title_1 }} {{appTextStatic.resultsStep.websiteLabel[language]}}</span>
                    <span v-if="language == 'cy'">{{appTextStatic.resultsStep.websiteLabel[language]}} {{ union.title_1 }}</span>
                    <span class="sr-only">({{appTextStatic.resultsStep.newTabLabel[language]}})</span>
                  </a>
              </div>
              <div class="quick email" v-if="union.field_general_email_address_unio.length">
                <strong>{{appTextStatic.resultsStep.emailLabel[language]}}:</strong>  <span v-html="union.field_general_email_address_unio"></span>
              </div>
              <div class="quick phone" v-if="union.field_general_phone_number.length">
                <strong>{{appTextStatic.resultsStep.telephoneLabel[language]}}:</strong>  <span>{{ union.field_general_phone_number }}</span>
              </div>
            </div>
          </div>
        </transition-slide-toggle>
      </div>
    </div>
    <div class="return"><a :href="siteUrl">{{appTextStatic.resultsStep.returnLink[language]}}</a></div>
    <h2 class="signoff">{{appText.results_signoff}}</h2>
    <back-to-top visibleoffset="350" bottom="58px" right="60px">
      <div class="btn btn-to-top" tabindex="0" @keyup.enter="scrollMe()"><span class="sr-only">{{appTextStatic.resultsStep.backToTopLabel[language]}}</span></div>
    </back-to-top>
    <back-button :goBackXSteps="2" />
  </div>
</template>

<script>
  import { AppText } from '@/store/AppText';
  import { mapState } from 'vuex';
  import TransitionSlideToggle from "./TransitionSlideToggle";
  import BackToTop from 'vue-backtotop'

  export default {
    name: "ResultsStep",
    components: {TransitionSlideToggle, BackToTop},
    data() {
      return {
        toggles: [],
        siteUrl: process.env.VUE_APP_TUC_URL + '/about-unions',
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
    computed: {
      ...mapState({
        unions: 'unions'
      })
    },
    
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;
      },
      unions: function(newVal) {
        this.toggles = []
        newVal.forEach(() => {
          this.toggles.push(false)
        })
      }
    },
    methods: {
      extractPlainURL(union){
        if(union.field_online_registration_form_1)
        {
          return union.field_online_registration_form_1.replaceAll('&amp;', '&');
        }
        
        return "";
      },
      scrollMe(){
        window.smoothscroll();
      },
      fixImgUrl(html) {
        return html.replace('src="/sites', 'src="'+process.env.VUE_APP_TUC_URL+'/sites')
      },
      extractUrl(html) {
        const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
        return html.match(urlRegex)[0];
      },
      toggleReadmore(i) {
        this.toggles.splice(i, 1, !this.toggles[i])
        this.analytics('results_read_more', this.unions[i].title_1)
      }
    }
  }
</script>
<style lang="scss">
  .vue-back-to-top{
    @media (max-width: 949px) {
      bottom: 168px !important;
      right: 6px !important;
    }
    @media (max-width: 450px) {
      bottom: 140px !important;
      right: 2px !important;
    }
  }

  .subscribed{
    background: #2bc96a;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 7px;
    border-radius: 4px;
    margin: 5px 0 20px 0;
    text-align: center;
    line-height: 1.4;
    font-size: 20px;
  }
  .btn-to-top{
    border-radius: 50% !important;
    height: 50px;
    width: 27px;
    margin-right: 10px;
    margin-bottom: 0;
    transition: 0.2s;
    // border: 2px solid #C92B51 !important;
    background: #C92B51;
    position: relative;



    &:before {
      border-style: solid;
      border-width: 0.2em 0.2em 0 0;
      border-color: #ffffff;
      content: "";
      display: inline-block;
      height: 1em;
      width: 1em;
      left: -0.5em;
      top: 0.90em;
      position: relative;
      transform: rotate(-45deg);
      vertical-align: top;
      transition: 0.2s;
    }

    &:hover{
      background-color: #E5C4CC;
      border-color: #C92B51;

      &:before{
        border-color: #C92B51;
      }
    }
  }
  .step {
    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
    &.results {
      width: 100%;
      max-width: 950px;
      background-color: #652E55;
      border-radius: 9px;
      padding: 60px 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        padding: 35px 18px 35px 18px;
        max-width: 100%;
      }

      h1 {
        text-align: center;
        font-size: 24px;
        line-height: 32px;
        max-width: 600px;
        margin: 0 auto 40px;
      }

      h2.signoff{
        text-align: center;
        font-size: 24px;
        line-height: 32px;
        max-width: 600px;
        margin: 40px auto 0;
      }

      .union_wrap {
        background: white;
        color: #535052;
        border-radius: 5px;
        margin-bottom: 30px;
        padding: 40px;
        display: flex;
        width: 100%;


        @media (max-width: 767px) {
          flex-wrap: wrap;
          padding: 17px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        .left {
          width: 30%;
          text-align: center;
          padding-right: 40px;
          @media (max-width: 767px) {
            width: 100%;
            padding: 0;

            img {
              max-height: 100px;
              width: auto;
              margin-bottom: 12px;
            }
          }
        }
        .right {
          width: 70%;

          @media (max-width: 767px) {
            width: 100%;
          }

          .readmore {
            overflow: hidden;

            .readmore_inner {
              padding-top: 20px;

              .represented {
                h3 {
                  margin-top: 0;
                }
              }
            }

            h3 {
              margin: 30px 0 20px;
            }
          }

          .quick {
            margin: 10px 0;

            &.web{
              strong{
                margin-right: 5px;
              }
            }

            &.email{
              a {
                line-break: anywhere;
              }
            }
          }
        }

        h2 {
          margin-top: 0;
          font-family: 'Avenir', sans-serif;
          font-size: 26px;
          line-height: 26px;
          margin-bottom: 0;

          @media (max-width: 767px) {
            font-size: 24px;
          }
        }

        .union_desc {
          font-size: 17px;
          line-height: 1.4;
        }
        .union_btns {
          @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100%;
          }
          .btn {
            font-size: 18px;
            font-weight: normal;
            height: 42px;
            line-height: 42px;
            margin-right: 10px;
            text-align: center;
            @media (max-width: 767px) {
              width: 100%;
              max-width: 300px;
              margin: 5px 0;
            }
          }
        }
      }
    }

  }
</style>
