import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import 'vue-select/dist/vue-select.css';
import VueHead from 'vue-head'
import VueCookies from 'vue-cookies'
import BackButton from "./components/BackButton.vue";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

extend('email', email);

extend('required', required);

Vue.config.productionTip = false
Vue.component('v-select', vSelect)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('back-button', BackButton)
Vue.use(VueHead)
Vue.use(VueCookies)
Vue.use(PerfectScrollbar)

Vue.$cookies.config('1y')

declare global {
  interface Window {
      fbq: any;
      gtag: any;
  }
}

Vue.mixin({
  methods: {
    analytics(action, label) {
      // console.log(action, label)
      if(typeof window.gtag === "function") {
        window.gtag('event', 'union_finder_tool', {
          'unionFinderCat': action,
          'unionFinderLabel': label
        });
      }
      
    },
  },
});

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
