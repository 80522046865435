<template>
  <header class="header">
    <div class="container">
      <div class="logo-container">
        <a :href="siteUrl" target="_blank"><span class="sr-only">{{appTextStatic.pageHeader.visitTUC[language]}}</span><Logo></Logo></a>
      </div>
      <div v-if="activeStep !== 6 || notFound" class="language_links">
        <button class="btn btn-red" @click="setNewLanguage('en')">
          English
        </button>

        <button class="btn btn-red" @click="setNewLanguage('cy')">
          Cymraeg
        </button>
      </div>
    </div>
  </header>
</template>

<script>
  import Logo from './Logo';
  import { mapMutations, mapActions, mapState } from 'vuex';

  export default {
    name: "PageHeader",
    components: {Logo},
    data() {
        return {
            siteUrl: process.env.VUE_APP_TUC_URL,
            language: null,
            appTextStatic: null,
        }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      const stateApp = this.$store.state;
      const language = this.$store.getters.getLanguage;
      this.setLanguage(language);
      
      const getAppTextByLanguage = this.getAppTextByLanguage;

      // start page loader
      stateApp.languageLoaded = false;
      
      const arr = [this.getAppText("en"), this.getAppText("cy")];

      Promise.allSettled(arr).then((results) => {
        
        // store both texts in both languages so we don't need to request again in the future
        stateApp.appTextEn = results[0].value;
        stateApp.appTextCy = results[1].value;
        
        // init text based on default language
        getAppTextByLanguage(language);
        
        stateApp.languageLoaded = true;
      });
    },
    computed: {
      ...mapState({
        activeStep: 'activeStep',
        notFound: 'notFound',
      })
    },
    methods: {
      ...mapMutations({
        setLanguage: 'setLanguage'
      }),
      ...mapActions({
        getAppText: 'getAppText'
      }),
      setNewLanguage(language="")
      {
        this.setLanguage(language);
        this.getAppTextByLanguage(language);
      },
      
      getAppTextByLanguage(language="")
      {
        if(language == "cy")
        {
          this.$store.state.appText = this.$store.state.appTextCy;
        }
        else
        {
          this.$store.state.appText = this.$store.state.appTextEn;
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .header {
    background-color: #000000;
    padding: 15px 15px 10px;
    height: 82px;

    @media (max-width: 767px) {
      height: 65px;
    }
  }

  .header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .language_links > *:first-child{
    margin-right: 30px;
  }

  .language_links .btn{
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    position: relative;
  }

  .language_links{
    padding-right: 20px;
  }

  .logo-container {
    width: 300px;
    height: 53px;

    .sr-only{
      color: #ffffff;
    }

    @media (max-width: 767px) {
      width: 200px;
      height: 35px;
    }
  }
  
  @media (max-width: 600px) {
    .language_links{
      padding-right: 0;
    }

    .language_links > *:first-child{
      margin-right: 20px;
    }

    .language_links .btn{
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      padding: 0 15px;
    }
  }

  @media (max-width: 450px) {
    .header .container{
      flex-direction: column;
      align-items: flex-start;
    }

    .header{
      height: 100px;
    }

    .main{
      padding-top: 20px;
    }

    .language_links {
      margin-top: 10px;
      text-align: right;
      width: 100%;
    }
  }
</style>
