<template>
  <div class="backbtn_wrap">
    <button class="btn btn-red" @click="back(goBackXSteps)">
      <span class="sr-only">Go back</span>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
    </button>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";

  export default {
    name: "BackButton",
    props: {
      goBackXSteps: {
        type: Number,
        default: 1,
      }
    },
    methods: {
      back(goBackXSteps) {
        this.$store.state.signUpCompleted = false; // reset the sign up form
        this.analytics('back_to', this.$store.state.activeStep-goBackXSteps)
        this.setActiveStep(this.$store.state.activeStep-goBackXSteps);
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep'
      })
    }
  }
</script>

<style lang="scss" scoped>
.backbtn_wrap {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 10px;

  @media (max-width: 767px) {
    top: 8px;
    left: 8px;
  }
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  padding: 0;
  svg {
    width: 9px;
    height: 14px;
    margin-right: 6px;
  }
}
</style>
